import AddressAutocomplete from 'components/MarketingSite/Header/AddressAutocomplete'
import ProjectListings from 'pages/_serverRendered/landing/ContractorsNearMe/ProjectListings'
import React, { FC, useCallback, useState } from 'react'
import { ProjectTemplate } from 'recoil/projectTemplates'
import { getJSON } from 'utils/fetch'
import { formatMoneyNoDecimals, titleCase } from 'utils/formatting'
import Header from 'components/MarketingSite/Header'
import publicPageStyles from 'pages/_serverRendered/Public/styles.module.scss'
import Footer from 'components/MarketingSite/Footer'
import GetRealmScoreCTA from 'pages/_serverRendered/PublicNeighborhood/GetRealmScoreCTA'
import cx from 'classnames'
import Spinner from 'components/Spinner'

import styles from './styles.module.scss'

interface Props {
  contractor_label: string
  contractor_type: string
  project_label: string
  projects: ProjectItem[]
  contractors: ContractorItem[]
}

interface ContractorsResponse {
  contractors: ContractorItem[]
  projects: ProjectItem[]
  project_costs: ProjectCost[]
}

interface ProjectCost {
  project_template: ProjectTemplate
  cost_estimate_high: number
  cost_estimate_low: number
}

export interface ProjectItem {
  id: string
  url: string
  image_url: string
  backup_image_url: string
  display_address: string
  bedroomscount: number
  bathcount: number
  areabuilding: number
  value: number
  project_value: number
  project_date: string
}

type ContractorItem = { contractor: string; propertyaddresscity: string; propertyaddressstate: string }

const ContractorsNearMe: FC<Props> = ({
  contractor_label,
  project_label,
  contractor_type,
  contractors: initialContractors,
  projects: initialProjects,
}) => {
  const [contractors, setContractors] = useState(initialContractors)
  const [projects, setProjects] = useState(initialProjects)
  const [projectCosts, setProjectCosts] = useState<ProjectCost[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [hasAddress, setHasAddress] = useState(false)

  const onPredictionSelected = useCallback(
    async ({ place_id }: Record<string, string>) => {
      setIsLoading(true)
      setHasError(false)
      setHasAddress(true)

      try {
        const response = await getJSON<ContractorsResponse>(
          `/api/v1/contractors?contractor_type=${contractor_type}&place_id=${place_id}`
        )

        if (response.contractors != null) {
          setContractors(response.contractors)
        }

        if (response.projects != null) {
          setProjects(response.projects)
        }

        if (response.project_costs != null) {
          setProjectCosts(response.project_costs)
        }
      } catch (error) {
        setHasError(true)
        setHasAddress(false)
      }

      setIsLoading(false)
    },
    [contractor_type]
  )

  // Convert label to lower case unless it's all caps (i.e. an acronym)
  const projectLabelLowercase =
    project_label.toUpperCase() === project_label ? project_label : project_label.toLowerCase()

  return (
    <div className={publicPageStyles.pageContainer}>
      <Header />
      <div className={cx(publicPageStyles.contentContainer, styles.content)}>
        <div className={styles.pricing}>
          <div>
            <h1>{`${contractor_label} near me`}</h1>
            <p>
              {
                'Finding the right contractor for your home project can feel overwhelming. Who should you hire? How do you know who to trust? Who will get the job done on time and within your budget? One good place to start: neighbors who recently completed similar projects. Those neighbors may be able to make recommendations (or help you steer clear of a subpar vendor). Plus, if they recommend someone who has completed several jobs in your area, that contractor is more likely to know your local building codes and regulations. Knowing which neighbors have recently completed similar projects in your neighborhood can be the first step toward finding the right person for the job.'
              }
            </p>
          </div>
          <AddressAutocomplete
            className={styles.search}
            onPredictionSelected={onPredictionSelected}
            positionLabel="contractors-near-me-page"
          />{' '}
          {(projectCosts.length > 0 || isLoading) && (
            <div className={styles.costs}>
              {isLoading ? (
                <LoadingState />
              ) : hasError ? (
                <ErrorState />
              ) : (
                projectCosts.map((projectCost) => (
                  <div key={projectCost.project_template.id}>
                    <h3>{projectCost.project_template.name}</h3>
                    <p className={styles.description}>{projectCost.project_template.description}</p>
                    <p className={styles.cost}>{`${formatMoneyNoDecimals(
                      projectCost.cost_estimate_low
                    )} - ${formatMoneyNoDecimals(projectCost.cost_estimate_high)}`}</p>
                  </div>
                ))
              )}
            </div>
          )}
          <a className={styles.button} href="/users/sign_up">
            {'Sign up to see project ROI for your property'}
          </a>
        </div>
        <div>
          <h2>{`Recent ${projectLabelLowercase} projects${hasAddress ? ' near me' : ''}`}</h2>
          <ProjectListings label={project_label} projects={projects} loading={isLoading} columns={3} />
        </div>
        <div>
          <h2>{`Top ${projectLabelLowercase} contractors${hasAddress ? ' near me' : ''}`}</h2>
          {isLoading ? (
            <LoadingState />
          ) : hasError ? (
            <ErrorState />
          ) : (
            <ul style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(auto-fill, 200px)' }}>
              {contractors.map((contractor, index) => (
                <li key={index}>
                  <p>{contractor.contractor}</p>
                  <p style={{ color: 'gray' }}>
                    {titleCase(contractor.propertyaddresscity)}
                    {', '}
                    {contractor.propertyaddressstate}
                  </p>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <GetRealmScoreCTA />
      <Footer />
    </div>
  )
}

const LoadingState = () => (
  <div>
    <Spinner size="md" />
  </div>
)
const ErrorState = () => <>{'Error'}</>

export default ContractorsNearMe
